.MuiAutocomplete-inputRoot{
  height: 42px;
  border-radius: 12px;
}
.topbar {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}
.sidenav-topnav{
  display: flex;
  height: 100%;
  width: 100%;
  border: 3px solid red;
}
.logo-parent {
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 0 28px;
  background-color: var(--color-white);
}

.container-1 {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.logo6 {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eazypayouts-2-1-icon6 {
  width: 1.55rem;
  height: 1.5rem;
}

.group-icon6 {
  width: 4.025rem;
  height: 1.675rem;
}

.menu03 {
  display: flex;
  height: 0.9rem;
  width: 0.9rem;
  transform: rotate(180deg);
  cursor: pointer;
}

.align-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectoutlined-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.selectoutlined2 {
  margin-right: var(--padding-13xl);
}

.container-2 {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 72px;
  box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
  background-color: var(--color-white);
  z-index: 15;
  box-sizing: border-box;
}

@media screen and (max-width: 949px) {
  .menu-03,
  .icon4,
  .logo6,
  .logo-parent,
  .eazypayouts-2-1-icon6,
  .group-icon6 {
    display: flex;
  }

  .selectoutlined-group {
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 40px;
  }

  .topbar {
    flex-direction: column;
  }

  .container-2 {
    box-shadow: none;
    background-color: var(--color-whitesmoke-300);
  }
}

@media screen and (max-width: 750px) {
  .selectoutlined-group {
    justify-content: flex-start;
    margin-left: 28px;
  }

  .logo-parent {
    height: 10%;
  }

  .container-2 {
    box-shadow: none;
    background-color: var(--color-whitesmoke-300);
  }
}

@media screen and (max-width: 450px) {
  .selectoutlined-group {
    justify-content: center;
  }

  .topbar {
    margin-bottom: 16px;
  }
}
