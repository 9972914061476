.Load-easypayouts-load-screen,
.Load-frame-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Load-easypayouts-load-screen {
  position: relative;
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--card-amount-size);
  color: var(--color-white);
  font-family: var(--font-sarabun);
  overflow-x: auto;
  background-color: var(--color-whitesmoke-300);
}

.Load-frame-parent4 {
  overflow-x: auto;
  flex-direction: column;
  gap: var(--gap-45xl);
  margin: 28px 28px 0 28px;
}

.Load-frame-parent5 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--color-white);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 24px 24px 0;
  gap: var(--gap-5xs);
  font-size: var(--card-amount-size);
  color: var(--color-midnightblue);
  overflow-x: auto;
  height: 115px;
  width: 354px;
  padding: 12px 32px;
}

.Load-card-container {
  display: flex;
  flex-direction: row;
  margin-right: 28px;
}

.Load-avaliable-balance-group,
.Load-frame-parent6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Load-frame-parent6 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Load-vector-container {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 8px;
}

.Load-vector-icon1 {
  width: 33.7px;
  align-self: center;
  height: 26px;
  margin-top: 4px;
}

.Load-div20 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 0.9rem;
  color: var(--green-1);
}

.Load-div16 {
  position: relative;
  color: var(--green-1);
  font-size: 16px;
  line-height: 0.9rem;
  margin-bottom: 8px;
}

.Load-acifsc {
  color: var(--color-midnightblue);
}

.Load-loads12 {
  font-size: 22px;
  line-height: 1.2rem;
  margin-bottom: 8px;
}
.table_Container{
  margin: 3px;
}
@media screen and (max-width: 750px) {
  .Load-frame-parent5 {
    width: 90vw;
    height: 17vh;
    max-height: 100px;
    border-radius: 16px;
    margin-bottom: 16px;
    padding-top: 6px;
    align-items: center;
  }
  .Load-div20 {
    font-size: 16px;
  }
  .Load-div16 {
    font-size: 14px;
  }
  .Load-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .Load-easypayouts-load-screen {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .Load-frame-parent5 {
    width: 95vw;
    height: 12vh;
    gap: 3px;
    margin-bottom: 18px;
    max-width: 369px;
    align-self: center;
    padding: 0 3px;
  }
  .Load-div20 {
    font-size: 14px;
  }
  .Load-loads12 {
    position: relative;
    letter-spacing: 0.15px;
    line-height: 1.2rem;
    font-size: 16px;
  }
  .Load-div16 {
    font-size: 12px;
  }
  .Load-card-container {
    display: flex;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Load-frame-parent4 {
    margin: 0;
    align-items: center;
  }
  .Load-vector-container {
    margin-left: 16px;
  }
}
