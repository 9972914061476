.page-interactive-screen{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex: 0.2;
  text-align: left;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  overflow-x: auto;

  min-width: 100vw;
  max-width: 100vw;
  /* max-height: 100vh; */
}
.route-container{
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  max-width: 100vw;
}
.route-contai{ 
  height: 100%;
  width: 100%;
}
.route-container-topnav{
  width: 100%;
}
.topnav{
  width:100%;
  position: sticky;
  top:0;
  z-index: 98;
}
.container_sidenave{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  max-height: 100vh;
  /* border: 5px solid green; */
}
.sidenave{
  height: 100%;
}
.inLogin{
  background-color: var(--color-whitesmoke-300);
}
@media screen and (max-width: 950px) {
  .container_sidenave{
    display: none;
  }
}
@media screen and (max-width: 750px) {
.container_sidenave{
  display: none;
}
}
@media screen and (max-width: 420px) {
.container_sidenave{
  display: none;
}
}