.container-login-background{
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  display: flex;
  width:100%;
  height: 100vh;
}
.container-login-input{
  flex: 0.55;
}
.content-input{
display: flex;
justify-content: center;
align-items: center;
z-index: 99;
border-radius: 20px;
}
.input-box-div{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right:  16%;
  padding-left: 16%;
}
.input-box{
  border-radius: 12px;
}
.input-box-div2{
padding-left: 10%;
padding-right: 10%;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px;
}
.container-login{
  position: absolute;
  background-image: url('../../../../../public/easypayouts-load-screen_3x.avif'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh; 
  display: flex;
  z-index: -1;
}
.login-div{
  display: flex;
}
.input-box-div{
  z-index: 2;

}
@media (max-width: 768px) {
  .container-login{
      background-size: cover;
      background-position: top;
      background-position: left;
      height: 100vh;
      filter: brightness(65%);
      z-index: -1;
  }
}
@media (max-width: 480px) {
  .container-login{
      background-size: cover;
      background-position: top;
      background-position: left;
      height: 100vh;
      filter: brightness(65%);
      z-index: -1;
  }
  .input-box-div{
    position: absolute;
    z-index: 1;
  }
}
@media (max-width: 350px) {
  .container-login {
    background-size: cover;
    background-position: top;
    background-position: left;
    height: 100vh;
    filter: brightness(65%);
    z-index: -1;
  }
}

