@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


.eazypayouts-2-1-icon2,
.group-icon2 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.eazypayouts-2-1-icon2 {
  width: 3.875rem;
  height: 3.75rem;
}
.group-icon2 {
  align-self: stretch;
  height: 1.188rem;
  max-width: 100%;
}
.logo2 {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-20xl);
  gap: var(--gap-base);
}
.loads-buttons2 {
  align-self: center;
  height: 2.5rem;
}
.statements-buttons-item {
  height: 1.125rem;
  color: black;
  width: 1.056rem;
  position: relative;
}
.statements1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  min-width: 5.125rem;
}
.payoutStatus1 {
  position: relative;
  letter-spacing: 0px;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  min-width: 5.925rem;
}
.statements1:active {
  font-weight: 700;
  font-family: var(--font-sarabun);
  color: var(--color-whitesmoke-200);
}
.statements-buttons2 {
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-43xl) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
  cursor: pointer;
  height: 2.5rem;
  color: slategrey;
  align-self: center;
}
.statements-buttons2:hover {
  background-color: var(--color-lightgray);
}
.statements-buttons2:active {
  background-color: var(--color-coral);
}
.arrow-down-up-icon1 {
  height: 1.125rem;
  color: black;
  width: 1.125rem;
  position: relative;
}
.loads1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  min-width: 5.688rem;
}
.loads1:active {
  font-weight: 700;
  font-family: var(--font-sarabun);
  color: var(--color-whitesmoke-200);
}
.loads-buttons2 {
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-33xl) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
  cursor: pointer;
  height: 2.5rem;
  color: slategrey;
}
.loads-buttons2:hover {
  background-color: var(--color-lightgray);
}
.loads-buttons2:active {
  background-color: var(--color-coral);
}
.transactions1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  min-width: 5.688rem;
}
.transactions1:active {
  font-weight: 700;
  font-family: var(--font-sarabun);
  color: var(--color-whitesmoke-200);
}
.transactions-buttons2 {
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  color: slategrey;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-33xl) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
  cursor: pointer;
  height: 2.5rem;
  color: slategrey;
  align-self: center;
}
.transactions-buttons2:hover {
  background-color: var(--color-lightgray);
}
.transactions-buttons2:active {
  background-color: var(--color-coral);
}
.hidden-div{
  display: none;
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.disabled :hover{
  cursor: not-allowed;
}
.actions {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  gap: var(--gap-9xl);
  /* border: 3px solid yellow; */
}
.logout{
  /* border: 2px solid aqua; */
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.logout-buttons2 {
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-74xl) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
  opacity: 0.96;
  height: 2.5rem;
  color:var(--color-slategray);
  justify-self: flex-end;
  cursor: pointer;
  /* border: 1px solid black; */
}
.logout-buttons2:hover {
  background-color: var(--color-lightgray);
}
.logout-buttons2:active {
  background-color: var(--color-coral);
}
.box-arrow-right-icon2 {
  height: 1rem;
  width: 1rem;
  position: relative;
}
.logout2 {
  /* position: relative; */
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: inline-block;
  min-width: 3.188rem;
}
.logout2:active {
  font-weight: 700;
  font-family: var(--font-sarabun);
  color: var(--color-whitesmoke-200);
}

.sidebar-content1,
.sidenavecontainer2 {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.sidebar-content1 {
  /* align-self: flex-start; */
  display: flex;
  justify-content: flex-start;
  padding: var(--padding-xl-5) var(--padding-xs);
  /* gap: 35vh; */
  height: 100%;
  max-height: 100vh;
  /* border: 4px solid blue; */
}
.sidenavecontainer2 {
  width: 12.5rem;
  box-shadow: 2px 2px 8px rgba(99, 99, 99, 0.2);
  justify-content: flex-start;
  padding: var(--padding-base) 0 0; 
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-slategray);
  font-family: var(--font-sarabun);
  height: 100%;
  max-height: 100vh;
}
.contain-sidenav{
  position: sticky; 
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100vh;

  /* z-index: 99; */
}
.hidden-div{
  display: none;
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.disabled :hover{
  cursor: not-allowed;
  background-color: none;
}
.disabled:active {
  font-weight: 300;
  font-family: var(--font-sarabun);
  color: none;
}