.getPayoutStatus {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 24px;
}
.payoutRefProcessed{
  color: green;
}
.payoutRefFailed{
  color: red;
}
.payoutRefPending{
  color: yellow;
}
.field {
  border: 0;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
}
.trailingIcon2 {
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  width: 24px;
  height: 24px;
  display: none;
}
.mobileMenu {
  align-self: stretch;
  position: relative;
  height: 56px;
}
.plusCircleIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.apply1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: left;
}
.apply {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-base);
  background-color: var(--color-coral);
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.mobileMenuParent,
.searchForPayoutStatus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.mobileMenuParent {
  width: 100%;
  justify-content: center;
}
.searchForPayoutStatus {
  box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
  border-radius: 16px;
  background-color: var(--color-white);
  max-width: 100%;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-midnightblue);
  font-family: var(--font-sarabun);
  margin:3px;
  min-width: 730px;
}
.payoutStatus {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 800;
  font-size: 24px;
}
.payoutRef{
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.payoutRef1success{

}
.frameGroup,
.payoutRefParent,
.payoutRefParent1,
.payoutRefParent2,
.payoutRefParent3,
.payoutRefParent4,
.payoutRefParent5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.payoutRefParent {
  gap: 47px;
}
.payoutRefParent1 {
  gap: 103px;
}
.payoutRefParent2 {
  gap:83px;
}
.payoutRefParent3 {
  gap: 15px;
}.payoutRefParent4 {
  gap: 48px;
}.payoutRefParent5 {
  gap: 43px;
}
.frameGroup {
  align-self: stretch;
  gap: var(--gap-base);
}
.utrParent {
  gap: 93px;
}
.frameContainer,
.statusParent,
.utrParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameContainer {
  gap: var(--gap-base);
}
.statusParent {
  gap: 75px;
}
.na,
.processed {
  position: relative;
}
.processed {
  letter-spacing: 0.4px;
  font-weight: 500;
  color: var(--green-1);
}
.na {
  letter-spacing: 0.15px;
  line-height: 24px;
}
.frameParent {
  align-self: stretch;
  gap: var(--gap-9xl);
  overflow: hidden;
}
.frameParent,
.searchForPayoutStatus,
.searchForPayoutStatusInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.searchForPayoutStatusInner {
  align-self: stretch;
  font-size: var(--font-size-base);
  color: var(--color);
}
.searchForPayoutStatus {
  width: 80%;
  box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
  border-radius:16px;
  background-color: var(--color-white);
  max-width: 100%;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-midnightblue);
  font-family: var(--font-sarabun);
}
.Load-card-container {
}
.Load-frame-parent5{
  margin-left: 2px;
  margin-top: 2px;
}
.Load-frame-parent4 {
  margin-bottom: 50px;
}
@media screen and (max-width: 750px) {
  .searchForPayoutStatus {
    margin: 20px 16px; 
    min-width: 95%;
  }
  .Load-frame-parent5 {
    width: 90vw;
    height: 17vh;
    max-height: 100px;
    border-radius: 16px;
    margin-bottom: 16px;
    padding-top: 6px;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-left: 25px;
  }
  .Load-div20 {
    font-size: 16px;
  }
  .Load-div16 {
    font-size: 14px;
  }
  .Load-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .Load-easypayouts-load-screen {
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 450px) {
  .payoutRef{
    font-size: 14px;
  }.payoutRef1{
    font-size: 14px;
  }
  .getPayoutStatus{
    font-size: 20px;
  }
  .searchForPayoutStatusInner{
    font-size: 18px;
  }
  .searchForPayoutStatus{
    min-width: 20px;
  }
  .payoutStatus{
    font-size: 16px;
  }
  .payoutRefParent {
    gap: 47px;
  }
  .payoutRefParent1 {
    gap: 90px;
  }
  .payoutRefParent2 {
    gap:76px;
  }
  .payoutRefParent3 {
    gap:24px;
  }
  .Load-frame-parent5 {
    width: 80%;
    height: 12vh;
    gap: 3px;
    margin-bottom: 18px;
    /* max-width: 369px; */
    align-self: center;
    padding: 0 3px;
  }
  .Load-div20 {
    font-size: 14px;
  }
  .Load-loads12 {
    position: relative;
    letter-spacing: 0.15px;
    line-height: 1.2rem;
    font-size: 16px;
  }
  .Load-div16 {
    font-size: 12px;
  }
  .Load-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Load-frame-parent4 {
    margin: 0;
    width: 100%;
    align-items: center;
  }
  .Load-vector-container {
    margin-left: 16px;
  }
}
