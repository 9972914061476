.easypayouts-load-screen,
.frame-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.easypayouts-load-screen {
  position: relative;
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--card-amount-size);
  color: var(--color-white);
  font-family: var(--font-sarabun);
  overflow-y:auto;
  overflow-x:auto;
  background-color:var(--color-whitesmoke-300);
  
}
.frame-parent4 {
  overflow-y: auto;
  overflow-x:auto;
  flex-direction: column;
  gap: var(--gap-45xl);
  margin: 28px 0 0 28px;
}
.table_Container{
  margin: 3px;
}