@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --light-components-button-large: Inter;
  --font-sarabun: Sarabun;
  --roboto-medium-16: Roboto;
  --card-amount: Nunito;
  --action-btn-txt-intable-lato: Lato;

  /* font sizes */
  --light-components-button-large-size: 15px;
  --font-size-xs: 12px;
  --card-amount-size: 18px;
  --card-heading-size: 24px;
  --action-btn-txt-intable-lato-size: 16px;

  /* Colors */
  --color-white: #fff;
  --color-midnightblue: #00005c;
  --blue-1: #2f80ed;
  --color-dodgerblue-100: #1976d2;
  --color-gray: #fefefe;
  --color-gray-100: #777;
  --color: #333;
  --color-darkslategray-100: rgba(51, 51, 51, 0.87);
  --green-1: #219653;
  --red: #eb5757;
  --color-whitesmoke-100: #f9f9f9;
  --color-whitesmoke-200: #f8f8f8;
  --color-whitesmoke-300: #f5f5f5;
  --color-slategray: #747582;
  --color-gainsboro: #d9d9d9;
  --color-lightgray: #cacaca;
  --color-coral: #fe914d;

  /* Gaps */
  --gap-111xl: 8.125rem;
  --gap-13xl: 2rem;
  --gap-46xl: 4.062rem;
  --gap-base: 1rem;
  --gap-5xs: 0.5rem;
  --gap-4xs: 0.562rem;
  --gap-9xs: 0.25rem;
  --gap-xs: 0.75rem;
  --gap-xl: 1.25rem;
  --gap-3xs: 0.625rem;
  --gap-6xs: 0.437rem;
  --gap-10xs: 0.187rem;
  --gap-9xl-1: 1.756rem;
  --gap-6xs-4: 0.4rem;
  --gap-373xl: 19.5rem;
  --gap-9xl: 1.75rem;

  /* Paddings */
  --padding-xl: 1.25rem;
  --padding-13xl: 2rem;
  --padding-366xl: 24.062rem;
  --padding-2xl: 1.312rem;
  --padding-231xl: 15.625rem;
  --padding-7xs: 0.375rem;
  --padding-71xl: 5.625rem;
  --padding-base: 1rem;
  --padding-xs: 0.75rem;
  --padding-mid: 1.062rem;
  --padding-mini: 0.937rem;
  --padding-2xs: 0.687rem;
  --padding-3xs: 0.625rem;
  --padding-10xs: 0.187rem;
  --padding-11xs: 0.125rem;
  --padding-sm: 0.875rem;
  --padding-9xl: 1.75rem;
  --padding-10xl: 1.812rem;
  --padding-11xl: 1.875rem;
  --padding-141xl: 10rem;
  --padding-xl-5: 1.281rem;
  --padding-5xs: 0.5rem;
  --padding-74xl: 5.812rem;
  --padding-33xl: 3.25rem;
  --padding-43xl: 3.875rem;
  --padding-20xl: 2.437rem;
  --padding-81xl: 6.25rem;

  /* Border radiuses */
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-3xs: 10px;

  /* Effects */
  --light-elevation-3: 0px 4px 8px -4px rgba(76, 78, 100, 0.42);
}
