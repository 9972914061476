
.SplashScreen {
    position: fixed; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5; 
    z-index: 9999; 
    max-height: 100vh;
    max-width: 100vw;
}
.splash_img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; 
}
